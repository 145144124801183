// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-practise-js": () => import("./../../../src/pages/practise.js" /* webpackChunkName: "component---src-pages-practise-js" */),
  "component---src-pages-rozvrh-tanecnich-salu-js": () => import("./../../../src/pages/rozvrh-tanecnich-salu.js" /* webpackChunkName: "component---src-pages-rozvrh-tanecnich-salu-js" */),
  "component---src-pages-tanecni-kurzy-js": () => import("./../../../src/pages/tanecni-kurzy.js" /* webpackChunkName: "component---src-pages-tanecni-kurzy-js" */),
  "component---src-pages-tanecni-kurzy-soutezni-tanecni-pary-dc-js": () => import("./../../../src/pages/tanecni-kurzy/soutezni-tanecni-pary-dc.js" /* webpackChunkName: "component---src-pages-tanecni-kurzy-soutezni-tanecni-pary-dc-js" */),
  "component---src-pages-tanecni-kurzy-tanecni-kurz-pro-am-pokrocili-js": () => import("./../../../src/pages/tanecni-kurzy/tanecni-kurz-pro-am-pokrocili.js" /* webpackChunkName: "component---src-pages-tanecni-kurzy-tanecni-kurz-pro-am-pokrocili-js" */),
  "component---src-pages-tanecni-kurzy-tanecni-kurz-pro-am-zacatecnici-js": () => import("./../../../src/pages/tanecni-kurzy/tanecni-kurz-pro-am-zacatecnici.js" /* webpackChunkName: "component---src-pages-tanecni-kurzy-tanecni-kurz-pro-am-zacatecnici-js" */),
  "component---src-pages-tanecni-kurzy-tanecni-pripravka-pro-deti-skupina-1-js": () => import("./../../../src/pages/tanecni-kurzy/tanecni-pripravka-pro-deti-skupina1.js" /* webpackChunkName: "component---src-pages-tanecni-kurzy-tanecni-pripravka-pro-deti-skupina-1-js" */),
  "component---src-pages-tanecni-kurzy-tanecni-pripravka-pro-deti-skupina-2-js": () => import("./../../../src/pages/tanecni-kurzy/tanecni-pripravka-pro-deti-skupina2.js" /* webpackChunkName: "component---src-pages-tanecni-kurzy-tanecni-pripravka-pro-deti-skupina-2-js" */),
  "component---src-pages-tanecni-kurzy-vrcholove-soutezni-tanecni-pary-bam-js": () => import("./../../../src/pages/tanecni-kurzy/vrcholove-soutezni-tanecni-pary-bam.js" /* webpackChunkName: "component---src-pages-tanecni-kurzy-vrcholove-soutezni-tanecni-pary-bam-js" */),
  "component---src-pages-treneri-irina-maizlish-js": () => import("./../../../src/pages/treneri/irina-maizlish.js" /* webpackChunkName: "component---src-pages-treneri-irina-maizlish-js" */),
  "component---src-pages-treneri-jaroslav-suchy-js": () => import("./../../../src/pages/treneri/jaroslav-suchy.js" /* webpackChunkName: "component---src-pages-treneri-jaroslav-suchy-js" */),
  "component---src-pages-treneri-jiri-pixa-js": () => import("./../../../src/pages/treneri/jiri-pixa.js" /* webpackChunkName: "component---src-pages-treneri-jiri-pixa-js" */),
  "component---src-pages-treneri-jiri-sevcik-js": () => import("./../../../src/pages/treneri/jiri-sevcik.js" /* webpackChunkName: "component---src-pages-treneri-jiri-sevcik-js" */),
  "component---src-pages-treneri-js": () => import("./../../../src/pages/treneri.js" /* webpackChunkName: "component---src-pages-treneri-js" */),
  "component---src-pages-treneri-libuse-hybsova-js": () => import("./../../../src/pages/treneri/libuse-hybsova.js" /* webpackChunkName: "component---src-pages-treneri-libuse-hybsova-js" */),
  "component---src-pages-treneri-marek-polacek-js": () => import("./../../../src/pages/treneri/marek-polacek.js" /* webpackChunkName: "component---src-pages-treneri-marek-polacek-js" */),
  "component---src-pages-treneri-milos-tancibudek-js": () => import("./../../../src/pages/treneri/milos-tancibudek.js" /* webpackChunkName: "component---src-pages-treneri-milos-tancibudek-js" */),
  "component---src-pages-treneri-monika-mihalikova-js": () => import("./../../../src/pages/treneri/monika-mihalikova.js" /* webpackChunkName: "component---src-pages-treneri-monika-mihalikova-js" */),
  "component---src-pages-treneri-petr-zabystrzan-js": () => import("./../../../src/pages/treneri/petr-zabystrzan.js" /* webpackChunkName: "component---src-pages-treneri-petr-zabystrzan-js" */),
  "component---src-pages-treneri-zdenek-rezler-js": () => import("./../../../src/pages/treneri/zdenek-rezler.js" /* webpackChunkName: "component---src-pages-treneri-zdenek-rezler-js" */)
}

